import { Auth0Provider } from '@auth0/auth0-react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  ErrorOutline,
  CheckCircleOutline,
  ReportProblemOutlined,
  InfoOutlined,
} from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { ReduxState } from 'OHSWebApp'
import RouterWithRoutes from 'navigation/router'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Provider as ReduxQueryProvider } from 'redux-query-react'
import createStore from 'redux/store'
import config from 'services/config'
import smoothscroll from 'smoothscroll-polyfill'
import { theme, Themed } from 'themes'

import './i18n'

smoothscroll.polyfill()

const store = createStore()

export const getQueries = (state: ReduxState) => state.queries

const useStyles = makeStyles()(() => ({
  success: {
    backgroundColor: theme.palette.success.light + ' !important',
    color: theme.palette.success.dark + ' !important',
  },
  error: {
    backgroundColor: theme.palette.error.light + ' !important',
    color: theme.palette.error.dark + ' !important',
  },
  warning: {
    backgroundColor: theme.palette.warning.light + ' !important',
    color: theme.palette.warning.dark + ' !important',
  },
  info: {
    backgroundColor: theme.palette.info.light + ' !important',
    color: theme.palette.info.dark + ' !important',
  },
}))

interface SnackProviderProps {
  children: React.ReactNode // if you have other props, define them here as well
}

const SnackProvider: React.FC<SnackProviderProps> = ({ children }) => {
  const { classes } = useStyles()
  return (
    <SnackbarProvider
      iconVariant={{
        success: <CheckCircleOutline htmlColor={theme.palette.success.main} />,
        error: <ErrorOutline htmlColor={theme.palette.error.main} />,
        warning: (
          <ReportProblemOutlined htmlColor={theme.palette.warning.main} />
        ),
        info: <InfoOutlined htmlColor={theme.palette.info.main} />,
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENTID}
    redirectUri={window.location.origin}
    audience={config.AUTH0_OHS_API_AUDIENCE}
    // @ts-ignore
    useRefreshTokens={window.Cypress ? false : true}
    scope="openid profile email"
    ui_locales="de"
    cacheLocation="localstorage"
  >
    <Themed>
      <SnackProvider>
        <Provider store={store}>
          <ReduxQueryProvider queriesSelector={getQueries}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <RouterWithRoutes />
            </LocalizationProvider>
          </ReduxQueryProvider>
        </Provider>
      </SnackProvider>
    </Themed>
  </Auth0Provider>,
  document.querySelector('#root')
)
